
.roles-div {
	div.bg-div {
		height: 300px;

		div:nth-child(2) {
			width: 100%;
			height: 300px;

			&.bg-gradient {
				background-image: linear-gradient(
					180deg,
					rgba(30, 64, 175, 0) 0%,
					#1e40af 100%
				) !important;
			}
		}

		h1 {
			font-weight: bold;
			color: #fff;
			font-size: 28px;
			margin: 0;
		}
	}
}
